import { Controller } from '@hotwired/stimulus';

var Highcharts = require('highcharts');
// Load module after Highcharts is loaded
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

export default class extends Controller {
  static targets = ['dayButton', 'weekButton'];
  static values = {
    condition: Number,
    dayData: Array,
    weekData: Object,
    date: String,
    intensityHigh: Number,
    intensityLow: Number,
    startTime: Number,
    endTime: Number
  };

  showDay() {
    this.weekButtonTarget.disabled = false;
    this.dayButtonTarget.disabled = true;
    this.showDayChart();
  }

  showWeek() {
    this.dayButtonTarget.disabled = false;
    this.weekButtonTarget.disabled = true;
    this.showWeekChart();
  }

  dayDataValueChanged() {
    this.showDayChart();
  }

  showDayChart() {
    let intensity = [];
    let ilow = [];
    let ihigh = [];
    let lowmin = 0;
    let highmin = 0;
    let medmin = 0;
    const pointWidth = 3;
    const color_low = '#DC1D0C';
    const color_high = '#01A008';

    this.dayDataValue.forEach((value) => {
      let item = [];
      let item_intensity = [];
      let intensity_low = [];
      let intensity_high = [];
      let datestr = new Date(value.time);
      let utc = Date.UTC(
        datestr.getFullYear(),
        datestr.getMonth(),
        datestr.getDate(),
        datestr.getHours(),
        datestr.getMinutes()
      );
      const datestr2 = new Date(this.dateValue + 'T00:00:00');
      var utc_start = Date.UTC(
        datestr2.getFullYear(),
        datestr2.getMonth(),
        datestr2.getDate(),
        0,
        0
      );
      const min_total = (utc - utc_start) / 60000;
      item.push(min_total, value.activity_counts);

      if (this.startTimeValue == 0) {
        item_intensity.push(utc, value.activity_counts);
        intensity.push(item_intensity);

        if (this.conditionValue > 0) {
          intensity_low.push(utc, parseInt(this.intensityLowValue));
          intensity_high.push(utc, parseInt(this.intensityHighValue));
          ilow.push(intensity_low);
          ihigh.push(intensity_high);
        }
      } else if (
        min_total > this.startTimeValue &&
        min_total < this.endTimeValue
      ) {
        item_intensity.push(utc, value.activity_counts);
        intensity.push(item_intensity);

        var min = value.activity_counts;

        if (this.conditionValue > 0) {
          intensity_low.push(utc, parseInt(this.intensityLowValue));
          intensity_high.push(utc, parseInt(this.intensityHighValue));

          ilow.push(intensity_low);
          ihigh.push(intensity_high);

          if (min >= parseInt(this.intensityHighValue)) {
            highmin++;
          } else if (
            min < parseInt(this.intensityHighValue) &&
            min > parseInt(this.intensityLowValue)
          ) {
            medmin++;
          } else if (min > 200 && min <= parseInt(this.intensityLowValue)) {
            lowmin++;
          }
        }
      }
    });

    this.settotalmin(0, 0, 0, '2');

    const chart = Highcharts.chart('totalExerciseChart', {
      chart: {
        type: 'column'
      },
      tooltip: {
        formatter: function () {
          return (
            '' +
            '' +
            'Time: ' +
            Highcharts.dateFormat('%I:%M %p', this.x) +
            ' ' +
            this.series.name +
            ': ' +
            this.y
          );
        }
      },

      yAxis: {
        title: {
          text: 'Intensity/Counts',
          style: {
            fontSize: '16px'
          }
        },
        labels: {
          style: {
            fontSize: '13px'
          }
        }
      },

      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          millisecond: '%I %p',
          second: '%I %p',
          minute: '%I:%M%P',
          hour: '%I %p'
        },
        title: {
          text: 'Time',
          style: {
            fontSize: '16px'
          }
        },
        labels: {
          style: {
            fontSize: '13px'
          }
        }
      },

      series: [
        {
          type: 'spline',
          name: 'Intensity Low',
          data: ilow,
          color: color_low,
          marker: {
            lineWidth: 2,
            lineColor: color_low,
            fillColor: 'white'
          }
        },

        {
          name: 'Intensity Values',
          data: intensity,
          color: '#D89DF1',
          pointWidth: pointWidth,
          visible: true
        },
        {
          type: 'spline',
          name: 'Intensity High',
          data: ihigh,
          color: color_high,
          marker: {
            lineWidth: 2,
            lineColor: color_high,
            fillColor: 'white'
          }
        }
      ],

      title: {
        text: ''
      },

      loading: false
    });
  }

  settotalmin(lowmin, highmin, medmin, condition) {
    if (parseInt(condition) == 2) {
      this.total_steps = highmin;
    } else if (parseInt(condition) == 1) {
      this.total_steps = lowmin;
    }

    var message = { low: lowmin, high: highmin, med: medmin };
  }

  showWeekChart() {
    const color_low = '#DC1D0C';
    const color_high = '#01A008';
    var days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    let dates = this.weekDataValue.dates;

    const chart = Highcharts.chart('totalExerciseChart', {
      chart: {
        type: 'column'
      },
      tooltip: {
        formatter: function () {
          // console.log(this.x);
          var index = days.indexOf(this.x);
          return (
            '' + 'Date: ' + this.x + ' ' + this.series.name + ': ' + this.y
          );
        }
      },

      legend: {
        reversed: true
      },

      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            color:
              (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
            style: {
              textShadow: '0 0 3px black'
            }
          }
        }
      },

      yAxis: {
        title: {
          text: 'Minutes',
          style: {
            fontSize: '16px'
          }
        },
        labels: {
          style: {
            fontSize: '13px'
          }
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
          }
        }
      },

      xAxis: {
        categories: days,

        title: {
          text: 'Date',
          style: {
            fontSize: '16px'
          }
        },
        labels: {
          style: {
            fontSize: '13px'
          }
        }
      },

      series: [
        {
          name: 'High Intensity',
          color: color_high,
          data: this.weekDataValue.high_intensity
        },
        {
          name: 'Med Intensity',
          color: '#ffc125',
          data: this.weekDataValue.medium_intensity
        },
        {
          name: 'Low Intensity',
          color: color_low,
          data: this.weekDataValue.low_intensity
        }
      ],

      title: {
        text: ''
      },

      loading: false
    });
  }
}
