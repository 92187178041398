import { Controller } from '@hotwired/stimulus';
var Highcharts = require('highcharts');
// Load module after Highcharts is loaded
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

export default class extends Controller {
  static targets = ['chart'];
  static values = {
    highIntensityMinutes: Number,
    mediumIntensityMinutes: Number,
    lowIntensityMinutes: Number
  };

  connect() {
    Highcharts.chart('weekAchievementChart', {
      chart: {
        type: 'bar',
        height: 200
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['']
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Minutes'
        }
      },
      legend: {
        reversed: true,
        align: 'right',
        verticalAlign: 'top',
        x: -20
      },
      plotOptions: {
        series: {
          pointWidth: 40,
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [
        {
          name: 'LS (Leg Symptoms)',
          data: [this.highIntensityMinutesValue],
          color: '#00A006'
        },
        {
          name: 'Medium',
          data: [this.mediumIntensityMinutesValue],
          color: '#ffc125'
        },
        {
          name: 'Low',
          data: [this.lowIntensityMinutesValue],
          color: '#DC1D0D'
        }
      ]
    });
  }
}
