import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'startHourInput',
    'startMinuteInput',
    'endHourInput',
    'endMinuteInput'
  ];

  connect() {}

  decrease(element, min, max) {
    if (parseInt(element.value) < min) {
      element.value = ('00' + min.toString()).slice(-2);
    } else if (parseInt(element.value) == min || element.value == '') {
      element.value = ('00' + max.toString()).slice(-2);
    } else {
      element.value = ('00' + (element.value - 1).toString()).slice(-2);
    }
  }

  increase(element, max, min) {
    if (parseInt(element.value) == max || element.value == '') {
      element.value = ('00' + min.toString()).slice(-2);
    } else if (parseInt(element.value) < min) {
      element.value = ('00' + min.toString()).slice(-2);
    } else {
      element.value = ('00' + (parseInt(element.value) + 1).toString()).slice(
        -2
      );
    }
  }

  increaseStartHour() {
    this.increase(this.startHourInputTarget, '12', '1');
  }

  decreaseStartHour() {
    this.decrease(this.startHourInputTarget, '1', '12');
  }

  increaseStartMinute() {
    this.increase(this.startMinuteInputTarget, '59', '0');
  }

  decreaseStartMinute() {
    this.decrease(this.startMinuteInputTarget, '0', '59');
  }

  increaseEndHour() {
    this.increase(this.endHourInputTarget, '12', '1');
  }

  decreaseEndHour() {
    this.decrease(this.endHourInputTarget, '1', '12');
  }

  increaseEndMinute() {
    this.increase(this.endMinuteInputTarget, '59', '0');
  }

  decreaseEndMinute() {
    this.decrease(this.endMinuteInputTarget, '0', '59');
  }
}
