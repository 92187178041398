import { Controller } from '@hotwired/stimulus';

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export default class extends Controller {
  static targets = [
    'audio',
    'badge',
    'message',
    'messagesCollection',
    'newMessage',
    'newMessagesQuery',
    'searchQuery'
  ];
  static values = {
    htmlUrl: String,
    jsonUrl: String,
    refreshInterval: Number,
    unreadMessageIds: Array
  };

  connect() {
    this.connected = true;

    setInterval(() => {
      this.loadUnreadMessages();
    }, this.refreshIntervalValue);
  }

  searchQueryTargetConnected() {
    const processSearch = debounce(() => this.filterMessages());
    this.searchQueryTarget.addEventListener('keyup', processSearch);
  }

  newMessagesQueryTargetConnected() {
    const processSearch = debounce(() => this.filterNewMessages());
    this.newMessagesQueryTarget.addEventListener('keyup', processSearch);
  }

  filterMessages() {
    this.messageTargets.forEach((message) => {
      if (
        !message
          .querySelector('.body')
          .innerText.match(new RegExp(this.searchQueryTarget.value, 'i'))
      ) {
        message.classList.add('hidden');
      } else {
        message.classList.remove('hidden');
      }
    });
  }

  filterNewMessages() {
    this.newMessageTargets.forEach((message) => {
      if (
        !message
          .querySelector('.participant')
          .innerText.match(new RegExp(this.newMessagesQueryTarget.value, 'i'))
      ) {
        message.classList.add('hidden');
      } else {
        message.classList.remove('hidden');
      }
    });
  }

  loadUnreadMessages() {
    if (this.hasMessagesCollectionTarget) {
      this.fetchMessagesForDisplay();
    }
    this.fetchMessagesForCount();
  }

  fetchMessagesForDisplay() {
    fetch(this.htmlUrlValue)
      .then((response) => response.text())
      .then((html) => {
        this.updateChat(html);
      });
  }

  fetchMessagesForCount() {
    fetch(this.jsonUrlValue)
      .then((response) => response.text())
      .then((json) => {
        let unreadMessages = JSON.parse(json);
        this.updateBadge(unreadMessages);
        this.updateUnreadMessageIds(unreadMessages);
      })
      .catch(() => {});
    // ignore fetch errors - most likely due to a session ending
  }

  updateBadge(unreadMessages) {
    if (this.hasBadgeTarget) {
      this.badgeTargets.forEach((badge) => {
        badge.innerHTML = unreadMessages.length;
      });
    }
  }

  updateChat(unreadMessages) {
    if (this.hasMessagesCollectionTarget) {
      this.messagesCollectionTarget.insertAdjacentHTML(
        'beforeend',
        unreadMessages
      );
      this.removeDuplicateMessages();
    }
  }

  removeDuplicateMessages() {
    let messageIds = [];
    this.messageTargets.forEach((messageElement) => {
      if (messageIds.includes(messageElement.dataset.messageId)) {
        messageElement.remove();
      } else {
        messageIds.push(messageElement.dataset.messageId);
      }
    });
  }

  updateUnreadMessageIds(unreadMessages) {
    if (
      this.hasUnreadMessageIdsValue &&
      typeof unreadMessages.map == 'function'
    ) {
      this.unreadMessageIdsValue = unreadMessages.map((m) => m.id);
    }
  }

  unreadMessageIdsValueChanged(ids, previousIds) {
    // this will get triggered when the values are first loaded, but
    // we want to ignore it the first time so the ding doesn't sound
    // on every page navigation
    if (this.connected == true) {
      if (ids.filter((id) => !previousIds.includes(id)).length > 0) {
        if (this.hasAudioTarget) {
          let playPromise = this.audioTarget.play();
          if (playPromise != null) {
            playPromise.catch(() => {
              alert(
                "In order to receive audio notifications when a message is received, please click on the page or use the keyboard. If that doesn't work, please visit your browser settings and allow this website to play audio content."
              );
            });
          }
        }
      }
    }
  }
}
