import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { participant: String, url: String };

  connect() {
    this.element.value = this.participantValue;
    this.element.addEventListener('change', (e) => {
      let studyIdentifier = e.target.value;
      window.location.href =
        this.urlValue + '?study_identifier=' + studyIdentifier;
    });
  }

  showOptions() {
    this.element.value = '';
    this.element.innerHTML = '';
  }
}
