import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { url: String };
  static targets = ['goalInput'];

  connect() {
    this.goalInputTarget.addEventListener('change', (e) => {
      window.location.href = `${this.urlValue}?week=${this.goalInputTarget.value}`;
    });
  }

  increase() {
    if (this.goalInputTarget.value < '0') {
      this.goalInputTarget.value = '0';
      window.location.href = `${this.urlValue}?week=${this.goalInputTarget.value}`;
    } else {
      this.goalInputTarget.value++;
      this.goalInputTarget.dispatchEvent(new Event('change'));
    }
  }

  decrease() {
    if (this.goalInputTarget.value < '0') {
      this.goalInputTarget.value = '0';
      window.location.href = `${this.urlValue}?week=${this.goalInputTarget.value}`;
      return;
    } else if (this.goalInputTarget.value === '0') {
      return;
    } else {
      this.goalInputTarget.value--;
      this.goalInputTarget.dispatchEvent(new Event('change'));
    }
  }
}
