import { Controller } from '@hotwired/stimulus';
import { MDCFormField } from '@material/form-field';
import { MDCCheckbox } from '@material/checkbox';
import { MDCRadio } from '@material/radio';

export default class extends Controller {
  static targets = ['checkbox', 'radio'];

  checkboxTargetConnected() {
    this.initializeFormField();
    this.checkbox = new MDCCheckbox(this.checkboxTarget);
    this.formField.input = this.checkbox;
  }

  checkboxTargetDisconnected() {
    if (this.checkbox) {
      this.formField.input = null;
      this.checkbox.destroy();
      this.formField.destroy();
    }
  }

  radioTargetConnected() {
    this.initializeFormField();
    this.radio = new MDCRadio(this.radioTarget);
    this.formField.input = this.radio;
  }

  radioTargetDisconnected() {
    if (this.radio) {
      this.formField.input = null;
      this.radio.destroy();
      this.formField.destroy();
    }
  }

  initializeFormField() {
    this.formField = new MDCFormField(this.element);
  }
}
