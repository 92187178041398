import { Controller } from '@hotwired/stimulus';

var Highcharts = require('highcharts');
// Load module after Highcharts is loaded
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

export default class extends Controller {
  static targets = ['chart'];
  // [low, medium, high]
  static values = { intensityData: Array };

  connect() {
    const chart = Highcharts.chart('minutesWalkedChart', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      accessibility: {
        description: 'minutes walked pie chart'
      },
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: -30,
            color: '#FFFFFF',
            format: '{y}',
            style: {
              fontSize: '25px'
            }
          },
          slicedOffset: 20,
          showInLegend: true
        }
      },
      series: [
        {
          name: 'Minutes',
          colorByPoint: true,
          data: [
            {
              name: 'Low',
              color: '#DC1D0D',
              y: this.intensityDataValue[0]
            },
            {
              name: 'Medium Intensity',
              color: '#ffc125',
              y: this.intensityDataValue[1]
            },

            {
              name: 'High',
              y: this.intensityDataValue[2],
              color: '#00A006'
            }
          ]
        }
      ]
    });
  }
}
