import { Controller } from '@hotwired/stimulus';
import { MDCBanner, CloseReason } from '@material/banner';

export default class extends Controller {
  static values = { shouldOpenNow: Boolean };

  connect() {
    this.banner = new MDCBanner(this.element);

    if (this.shouldOpenNowValue) {
      this.banner.open();
    }
  }

  disconnect() {
    if (this.banner) {
      this.banner.destroy();
    }
  }

  open(event) {
    if (event != null) {
      this.banner.setText(event.detail);
    }
    this.banner.open();
  }

  close() {
    this.banner.close();
  }
}
