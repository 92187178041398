import { Controller } from '@hotwired/stimulus';

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export default class extends Controller {
  static targets = ['badge', 'conversation', 'conversationCollection'];
  static values = {
    participantJsonUrl: String,
    refreshInterval: Number
  };

  connect() {
    this.connected = true;

    setInterval(() => {
      this.loadUnreadMessages();
    }, this.refreshIntervalValue);
  }

  loadUnreadMessages() {
    this.conversationTargets.forEach((participantElement) => {
      fetch(this.participantJsonUrlValue)
        .then((response) => response.text())
        .then((json) => {
          let unreadMessages = JSON.parse(json);
          this.badgeTarget.innerHTML = unreadMessages.length;
        })
        .catch(() => {});
      // ignore fetch errors - most likely due to a session ending
    });
  }
}
