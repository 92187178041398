import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('ajax:error', function (event) {
      event.preventDefault();
      location.reload();
    });
  }
}
