import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import { MDCSelect } from '@material/select';
import { MDCSelectHelperText } from '@material/select/helper-text';

export default class extends Controller {
  static targets = ['helperText', 'hiddenInput'];

  connect() {
    this.select = new MDCSelect(this.element);
    this.select.listen('MDCSelect:change', () => {
      this.onSelection();
    });
  }

  disconnect() {
    if (this.select) {
      this.select.destroy();
    }
  }

  helperTextTargetConnected(element) {
    this.helperText = new MDCSelectHelperText(element);
  }

  helperTextTargetDisconnected() {
    if (this.helperText) {
      this.helperText.destroy();
    }
  }

  onSelection() {
    this.hiddenInputTarget.value = this.select.value;

    if (this.element.dataset.submitOnChange == 'true') {
      this.submitParentForm();
    } else {
      this.handleChangeLocally();
    }
  }

  submitParentForm() {
    this.element.closest('form').querySelector('input[type="submit"]').click();
  }

  handleChangeLocally() {
    // hidden inputs don't normally broadcast any events
    this.hiddenInputTarget.dispatchEvent(
      new CustomEvent('change', { detail: this.select.value })
    );

    if (this.hiddenInputTarget.dataset['remote']) {
      Rails.ajax({
        url: this.hiddenInputTarget.dataset['url'],
        type: 'get',
        data: new URLSearchParams({
          [this.hiddenInputTarget.name]: this.select.value
        }).toString(),
        dataType: 'json'
      });
    }
  }
}
