import { Controller } from '@hotwired/stimulus';

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export default class extends Controller {
  static targets = ['participant', 'searchQuery'];

  connect() {
    this.connected = true;
  }

  searchQueryTargetConnected() {
    const processSearch = debounce(() => this.filterParticipants());
    this.searchQueryTarget.addEventListener('keyup', processSearch);
  }

  filterParticipants() {
    this.participantTargets.forEach((participant) => {
      if (
        !participant
          .querySelector('.name')
          .innerText.match(new RegExp(this.searchQueryTarget.value, 'i')) &&
        !participant
          .querySelector('.study-identifier')
          .innerText.match(new RegExp(this.searchQueryTarget.value, 'i'))
      ) {
        participant.classList.add('hidden');
      } else {
        participant.classList.remove('hidden');
      }
    });
  }
}
