import { Controller } from '@hotwired/stimulus';
import { MDCTextField } from '@material/textfield';

export default class extends Controller {
  connect() {
    this.textField = new MDCTextField(this.element);
    // mark initialization complete for testing purposes
    this.element.dataset.mdcInitialized = true;
  }

  disconnect() {
    if (this.textField) {
      this.textField.destroy();
    }
  }
}
