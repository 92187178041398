import { Controller } from '@hotwired/stimulus';

var Highcharts = require('highcharts');
// Load module after Highcharts is loaded
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

export default class extends Controller {
  static targets = ['chart'];
  static values = { weeklyData: Array, weeklyGoal: Array };

  connect() {
    this.castValues();
    const chart = Highcharts.chart('progressChart', {
      chart: {
        zoomType: 'xy'
      },
      accessibility: {
        description: 'weekly progress chart'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        crosshair: true,
        title: {
          text: 'Weekly Walking Exercise Goal Achievement',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        }
      },
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value}',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          title: {
            text: 'Minutes',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          gridLineWidth: 0
        }
      ],
      tooltip: {
        shared: true
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        x: 0,
        verticalAlign: 'top',
        y: 0,
        floating: true,
        backgroundColor:
          (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
          '#FFFFFF',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      series: [
        {
          name: 'Minutes achieved',
          type: 'column',
          dataLabels: { enabled: true },
          color: '#90c695',
          data: [
            { y: this.weeklyDataValue[0], color: '#2ec771' },
            { y: this.weeklyDataValue[1], color: '#2ec771' },
            { y: this.weeklyDataValue[2], color: '#2ec771' },
            { y: this.weeklyDataValue[3], color: '#2ec771' },
            { y: this.weeklyDataValue[4], color: '#2ec771' },
            { y: this.weeklyDataValue[5], color: '#2ec771' },
            { y: this.weeklyDataValue[6], color: '#2ec771' }
          ],
          tooltip: {
            valueSuffix: ' min'
          }
        },

        {
          name: 'Goal',
          type: 'line',
          dashStyle: 'longdash',
          data: [
            { y: this.weeklyGoalValue[0], marker: { symbol: 'square' } },
            { y: this.weeklyGoalValue[1], marker: { symbol: 'square' } },
            { y: this.weeklyGoalValue[2], marker: { symbol: 'square' } },
            { y: this.weeklyGoalValue[3], marker: { symbol: 'square' } },
            { y: this.weeklyGoalValue[4], marker: { symbol: 'square' } },
            { y: this.weeklyGoalValue[5], marker: { symbol: 'square' } },
            { y: this.weeklyGoalValue[6], marker: { symbol: 'square' } }
          ],
          tooltip: {
            valueSuffix: ' min'
          }
        }
      ]
    });
  }

  castValues() {
    this.weeklyDataValue = this.weeklyDataValue.map(Number);
    this.weeklyGoalValue = this.weeklyGoalValue.map(Number);
  }
}
